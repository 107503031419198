<template>
  <v-layout id="market-rate-container" class="market-container" column>
    <div class="market-pricing-title">Automated Pricing</div>
    <CRSelect
      id="market-rate-selector"
      v-model="selected"
      label="Market"
      class="market-selector"
      :items="markets"
      :item-text="'marketName'"
      :item-value="'marketId'"
      return-object
      :menu-props="{ overflowY: true, maxHeight: '300px !important' }"
    />

    <v-layout
      v-for="(rate, i) in curatedList"
      :id="`market-rate-list-${i}`"
      :key="i"
      class="curated-list"
      column
    >
      <div class="curated-label">
        {{ rate.label }}
      </div>
      <v-layout
        v-for="(sub, k) in rate.rates"
        :key="k"
        row
        class="curated-type-container"
      >
        <div class="curated-type">
          {{ sub.type }}
        </div>
        <div class="curated-rate">
          {{ sub.rate }}
        </div>
      </v-layout>
    </v-layout>
  </v-layout>
</template>
<script>
import markets from '@/services/markets'
import { currencyFilter } from '@/utils/currency'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    quote: {
      type: Object,
      default: () => ({}),
    },
    tripIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      markets: [],
      curatedList: [],
      selected: null,
      vehicleTypes: [],
    }
  },
  computed: {
    ...mapGetters({
      marketChange: 'quotes/getSelectedMarketChanges',
    }),
    currentTrip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
  },
  watch: {
    'quote.trips': {
      deep: true,
      handler: function () {
        if (this.quote.leadSource?.id) {
          this.generateList()
        }
      },
    },
    'currentTrip.pricingMarket': function () {
      if (!this.selected) {
        this.selected = { marketId: this.currentTrip.pricingMarket }
        return
      }

      if (
        this.currentTrip.pricingMarket &&
        this.currentTrip.pricingMarket !== this.selected.marketId
      ) {
        this.selected = { marketId: this.currentTrip.pricingMarket }
      }
    },
    selected: function () {
      this.quote.trips[this.tripIndex].pricingMarket = this.selected?.marketId
      this.setSelectedMarket({ market: this.selected, index: this.tripIndex })
      EventBus.$emit('set-pricing-market', this.selected?.marketId)

      this.generateList()
    },
    marketChange: function () {
      if (!this.selected) {
        this.selected = this.getSelectedMarket()(this.tripIndex)
        return
      }

      if (
        this.getSelectedMarket()(this.tripIndex) &&
        this.getSelectedMarket()(this.tripIndex).marketId !==
          this.selected.marketId
      ) {
        this.selected = this.getSelectedMarket()(this.tripIndex)
      }
    },
  },
  async mounted() {
    try {
      await this.setDefault()

      const response = await this.getQuoteTypes()
      this.vehicleTypes = response.data.vehicleTypes.filter(
        (vehicleType) => vehicleType.key !== 'unassigned'
      )

      if (this.quote.leadSource?.id) {
        await this.generateList()
      }
    } catch (e) {
      console.error('An error occurred:', e)
      this.vehicleTypes = []
    }
  },
  methods: {
    ...mapActions({
      getQuoteTypes: 'types/getQuoteTypes',
      setSelectedMarket: 'quotes/setSelectedMarket',
    }),
    ...mapGetters({
      getSelectedMarket: 'quotes/getSelectedMarket',
    }),
    async setDefault() {
      const { trips = [] } = this.quote
      const availableMarkets = await markets
        .tableView({ pageSize: -1 })
        .then((data) => data.data.resultList)
      this.markets = availableMarkets
      let defaultMarket = null
      if (trips[this.tripIndex]) {
        if (typeof trips[this.tripIndex].pricingMarket === 'number') {
          const matchingMarket = this.markets.find(
            (m) => m.marketId === trips[this.tripIndex].pricingMarket
          )
          defaultMarket = matchingMarket
        }
      }
      this.selected = defaultMarket

      this.generateList()
    },
    async generateList() {
      this.curatedList = []
      if (!this.quote.leadSource && !this.quote.leadSource?.id) {
        return
      }

      if (!this.selected) return

      const tripVehicles =
        this.quote?.trips?.[this.tripIndex]?.vehicles ||
        this.quote?.trips?.[this.tripIndex]?.requiredVehicles

      if (!tripVehicles) return

      let queryMarket = await markets
        .byId(this.selected.marketId)
        .then((result) => result.data.market.marketRateDTOs)

      let queryResults = queryMarket
        .filter((entry) => {
          return entry.marketplace === true
        })
        .filter((entry) => {
          if (
            this.quote.leadSource?.key?.toLowerCase().indexOf('gogo') > -1 ||
            this.quote.leadSource?.key?.toLowerCase().indexOf('shofur') > -1
          ) {
            return entry.companyId === 2
          }
          return entry.companyId === this.quote.leadSource?.partnerCompanyId
        })

      queryResults = queryMarket.reduce((acc, entry) => {
        const targetVehicle = tripVehicles.find((vehicle) => {
          const targetLabel = this.vehicleTypes.find(
            (targetVehicle) => targetVehicle.id === vehicle.vehicleType?.id
          )
          if (!targetLabel) {
            return
          }
          return targetLabel?.label === entry?.vehicleTypeDTO?.label
        })

        if (!targetVehicle) return acc
        if (acc[entry.vehicleTypeDTO.label]) {
          acc[entry.vehicleTypeDTO.label].rates.push({
            type: entry.marketRateTypeDTO.label,
            rate:
              currencyFilter(entry.lowRate) +
              ' - ' +
              currencyFilter(entry.highRate),
          })
        } else {
          acc[entry.vehicleTypeDTO.label] = {
            rates: [],
            label: entry.vehicleTypeDTO.label,
          }
          acc[entry.vehicleTypeDTO.label].rates.push({
            type: entry.marketRateTypeDTO.label,
            rate:
              currencyFilter(entry.lowRate) +
              ' - ' +
              currencyFilter(entry.highRate),
          })
        }
        return acc
      }, {})

      Object.keys(queryResults).forEach((t) => {
        this.curatedList.push(queryResults[t])
      })

      const uniq = {}
      this.curatedList = this.curatedList.filter((o) => {
        if (uniq[o.label]) return false

        uniq[o.label] = true
        return true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .market-selector label {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35em;
  letter-spacing: normal;
}

.market-container {
  max-width: 25%;
  margin-right: 2%;
  margin-bottom: 5%;

  .market-pricing-title {
    margin-bottom: 5%;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.35em;
    letter-spacing: normal;
  }

  .curated-list {
    margin-bottom: 5%;

    .curated-label {
      font-weight: 600;
    }

    .curated-type-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .curated-rate {
        font-weight: 600;
      }

      .curated-type {
        color: $gray-medium-light;
      }
    }
  }
}
</style>
