<template>
  <v-layout column class="footer-contents">
    <v-form ref="form">
      <v-layout row>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <CRIcon
                :color="hasManagerOverride ? 'blue' : 'grayMediumLight'"
                class="mr-1"
                :width="20"
                :height="20"
                @click.native="() => (showSelectMarket = !showSelectMarket)"
              >
                distance
              </CRIcon>
            </span>
          </template>
          <div class="px-4">Selected Market: {{ selectedMarketName }}</div>
        </v-tooltip>
        <div class="space-right">
          <strong>Dead Mile:</strong>
          &nbsp;
          <v-progress-circular
            v-if="calculatingEstimations"
            :size="12"
            :width="1"
            indeterminate
            color="primary"
          />
          <span v-else>{{ deadMileLabel }}</span>
        </div>
        <div class="space-right">
          <strong>Live Miles:</strong>
          &nbsp;
          <v-progress-circular
            v-if="calculatingEstimations"
            :size="12"
            :width="1"
            indeterminate
            color="primary"
          />
          <span v-else>{{ liveMileLabel }}</span>
        </div>
        <div class="space-right">
          <strong>Estimated Time:</strong>
          &nbsp;
          <v-progress-circular
            v-if="calculatingEstimations"
            :size="12"
            :width="1"
            indeterminate
            color="primary"
          />
          <span v-else>{{ estimatedTimeLabel }}</span>
        </div>
        <div v-if="showPricingTime" class="space-right">
          <strong>Pricing Time:</strong>
          &nbsp;
          <v-progress-circular
            v-if="calculatingEstimations"
            :size="12"
            :width="1"
            indeterminate
            color="primary"
          />
          <span v-else>{{ pricingTimeLabel }}</span>
        </div>
      </v-layout>
      <v-layout v-if="toggled && showSelectMarket" column class="calculator">
        <v-flex>
          <strong>Auto-Detected Nearest Available Market:</strong>
          {{ autoDetectedNearestMarketName }}
        </v-flex>
        <v-flex class="subtitle">Select Market</v-flex>
        <v-layout row class="selection-line" xs6>
          <v-layout xs6>
            <v-flex xs8 sm6 md4>
              <CRSelect
                :id="`quote-form-trip-${tripIndex}-payment-select-market`"
                :value="selectedMarketId"
                class="market-selector"
                flat
                placeholder="Select Market"
                solo
                :items="markets"
                :item-text="'marketName'"
                :item-value="'marketId'"
                :return-object="true"
                :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
                :rules="quote.charterUpQuote ? marketRules : []"
                :disabled="isModeView"
                @change="changeMarketHandler"
              />
            </v-flex>
          </v-layout>
          <v-layout xs8 />
        </v-layout>
      </v-layout>
    </v-form>
  </v-layout>
</template>

<script>
import markets from '@/services/markets'
import { mapActions, mapGetters } from 'vuex'
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired } from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    quote: { type: Object, default: () => {} },
    toggled: { type: Boolean, default: false },
    calculatingEstimations: { type: Boolean, default: false },
    tripIndex: { type: Number, default: 0 },
    tripData: {
      type: Object,
      default: () => ({
        rates: [],
        charges: [],
        stops: [],
        distance: 0,
        duration: 0,
        hourlyDiff: 0,
        mileageRate: 0,
        pricingMarket: null,
        autoDetectedMarketId: null,
        hourlyRate: 0,
        dailyRate: 0,
        deadMileCalcus: 0,
        isManuallySelectedPricingMarket: false,
      }),
    },
    estimatedTimeLabel: { type: String, default: '' },
    deadMileLabel: { type: String, default: '' },
    liveMileLabel: { type: String, default: '' },
    pricingTimeLabel: { type: String, default: '' },
    pricingMarket: { type: Number, default: null },
    mode: { type: String, default: undefined },
  },
  data() {
    return {
      selectedMarket: null,
      markets: [],
      rateMatrix: {},
      deadMileDistance: 0,
      marketManuallyChanged: false,
      marketRules: [
        isRequired(true, isNotEmpty, {
          req: 'Market is Required',
          error: 'Market is Required',
        }),
      ],
      showSelectMarket: false,
    }
  },
  computed: {
    ...authComputed,
    showPricingTime() {
      const roles = this.currentUserProfile?.roles || []
      return !!roles.find((r) => r.roleName === 'can_view_pricing_logs')
    },
    ...mapGetters({
      selectedMarketStoreChanges: 'quotes/getSelectedMarketChanges',
      autoDetectedNearsetMarketStoreChanges:
        'quotes/getAutoDetectedNearestMarketChanges',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    autoDetectedNearestMarketName() {
      return this.autoDetectedNearsetMarketStoreChanges[this.tripIndex]
        ? this.autoDetectedNearsetMarketStoreChanges[this.tripIndex].marketName
        : null
    },
    savedPricingMarket() {
      return this.quote?.trips?.[this.tripIndex]?.savedPricingMarket || null
    },
    selectedMarketName() {
      if (
        ['view', 'edit'].includes(this.mode) &&
        !this.marketManuallyChanged &&
        this.savedPricingMarket
      ) {
        const matchingMarket = this.markets.find(
          (m) => m.marketId === this.savedPricingMarket
        )
        return matchingMarket?.marketName
      }

      if (!this.selectedMarketStoreChanges) {
        return null
      }

      return this.selectedMarketStoreChanges[this.tripIndex]
        ? this.selectedMarketStoreChanges[this.tripIndex].marketName
        : null
    },
    selectedMarketId() {
      if (
        ['view', 'edit'].includes(this.mode) &&
        !this.marketManuallyChanged &&
        this.savedPricingMarket && this.tripData.active
      ) {
        return this.savedPricingMarket
      }

      return this.selectedMarketStoreChanges[this.tripIndex]
        ? this.selectedMarketStoreChanges[this.tripIndex].marketId
        : null
    },
  },
  watch: {
    pricingMarket: {
      immediate: true,
      async handler(value, oldValue) {
        if (!oldValue) {
          try {
            const response = await markets.tableView({ pageSize: -1 })
            this.markets = response.data?.resultList || []
          } catch (e) {
            // Handle error if needed
            this.markets = []
          }
        }

        let defaultMarket = null

        if (typeof value === 'number') {
          const matchingMarket = this.markets.find((m) => m.marketId === value)
          defaultMarket = matchingMarket
        }

        if (defaultMarket) {
          this.setSelectedMarket({
            market: defaultMarket,
            index: this.tripIndex,
          })
          EventBus.$emit('set-pricing-market', this.defaultMarket?.marketId)
        }
      },
    },
    'tripData.nearestMarketId': {
      handler(val) {
        const nearestMarket = this.markets.find(
          (market) => market.marketId === val
        )
        this.setSelectedMarket({
          market: nearestMarket,
          index: this.tripIndex,
        })
        EventBus.$emit('set-pricing-market', this.nearestMarket?.marketId)
      },
    },
    'tripData.autoDetectedMarketId': {
      handler(val) {
        const market = this.markets.find((market) => market.marketId === val)
        this.setAutoDetectedNearestMarket({
          market: market,
          index: this.tripIndex,
        })
        EventBus.$emit(
          'set-auto-detected-nearest-market',
          this.market?.marketId
        )
      },
    },
  },
  mounted() {
    this.marketManuallyChanged = false
  },
  methods: {
    ...mapActions({
      setSelectedMarket: 'quotes/setSelectedMarket',
      setAutoDetectedNearestMarket: 'quotes/setAutoDetectedNearestMarket',
      setTripManuallySelectedPricingMarket:
        'quoteForm/setTripManuallySelectedPricingMarket',
    }),
    ...mapGetters({
      getSelectedMarket: 'quotes/getSelectedMarket',
    }),
    async handleSelectMarket(market) {
      await this.setSelectedMarket({
        market,
        index: this.tripIndex,
      })
      EventBus.$emit('set-trip-pricing-market-manually-selected', this.tripIndex)
      EventBus.$emit('set-pricing-market', this.market?.marketId)
    },
    async changeMarketHandler(market) {
      await this.handleSelectMarket(market)
      this.marketManuallyChanged = true
    },
    validate() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss" scoped>
.space-right {
  margin-right: 8px;
}

.subtitle {
  margin-top: 10px;
  font-size: 15px;
}

.market-selector {
  position: relative;
  top: 15px;
}

.v-tooltip {
  z-index: 10;
  position: relative;
}
</style>
