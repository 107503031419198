import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const quotesCustomerAccountTableView = async (params) => {
  const {
    customerAccountId,
    pageSize,
    page,
    sorts,
  } = params

  let query = `page=${page}&pageSize=${pageSize}`

  if (sorts) {
    query += `&${sorts}`
  }

  query = encodeURI(query)

  const host = baseUrl()
  const url = `https://${baseUrl()}/quotes/customerAccount/${params.customerAccountId}?${query}`
  return axios.get(url)
}

export const quoteById = async (quoteId) =>
  axios.get(`https://${baseUrl()}/v2/quotes/${quoteId}`)

export const quoteDetailV2 = async (quoteId) =>
  axios.get(`https://${baseUrl()}/quoteDetail/v2/${quoteId}`)

export const convertQuote = async (
  quote,
  paymentInfo = null,
  paymentMethodData = {},
  bidIds = [],
  paymentProfileId = null,
  showOnCharterUp = false
) => {
  let payload

  if (!quote) {
    throw new Error('Missing parameters in convertQuote()')
  }

  delete paymentMethodData.cardNumber
  delete paymentMethodData.securityCode

  // Either we're dealing with manual conversion of a quote when paying after services or we're dealing with checkout. Whether we have a token should be a good indicator.
  if (paymentProfileId) {
    payload = {
      paymentProfileId: paymentProfileId,
      payment_method: paymentMethodData.activeMethod,
      billing: paymentMethodData,
      payment_gateway: paymentInfo,
      bidIds,
      quoteConvertType: 'charterup',
    }
  } else if (paymentInfo) {
    payload = {
      nonces: paymentInfo.tokens,
      payment_method: paymentMethodData.activeMethod,
      billing: paymentMethodData,
      payment_gateway: paymentInfo.paymentGateway,
      bidIds,
      quoteConvertType: 'charterup',
    }
  } else {
    payload = {
      meta: {
        notes: 'Manual conversion',
      },
    }
  }

  const response = await axios.post(
    `https://${baseUrl()}/quotes/${
      quote.quoteId
    }/convert?showOnCharterUp=${showOnCharterUp}&doneByCustomer=false`,
    payload
  )
  if (response && response.data && response.data.successful === true) {
    return response
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error('Unspecified error while converting quote.')
  }
}

export const confirmQuote = async (quoteId) => {
  return axios.get(`https://${baseUrl()}/quotes/${quoteId}/confirm`)
}
