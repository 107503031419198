<template>
  <v-container class="trip-detail" style="padding: 0">
    <div class="trip-detail-info">
      <v-form ref="trip-detail-form">
        <v-layout padded wrap>
          <v-flex md12 lg6>
            <v-layout padded>
              <v-flex xs6>
                <CRSelect
                  :id="`quote-form-trip-${tripIndex}-type`"
                  v-model="tripData.tripType.id"
                  :items="tripTypes"
                  item-text="label"
                  item-value="id"
                  label="Trip Type"
                  placeholder="e.g. Round Trip, One Way, etc."
                  browser-autocomplete="off"
                  :disabled="isModeView"
                  use-svg-icons
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Trip Type Is Required',
                            error: 'Trip Type Is Required',
                          }),
                        ]
                      : []
                  "
                  @input="onTripTypeChange"
                />
                <v-switch
                  v-if="isShuttleTrip && areLoopingShuttleItinerariesEnabled"
                  :id="`quote-form-trip-${tripIndex}-is-non-scheduled-loop`"
                  v-model="tripData.hasCirculation"
                  label="Non-scheduled Loop"
                  hide-details
                  :disabled="isModeView"
                  color="primary"
                  class="margin-t-0 padding-t-0"
                />
              </v-flex>
              <v-flex xs3>
                <CRInput
                  :id="`quote-form-trip-${tripIndex}-num-passengers`"
                  v-model="tripData.passengerCount"
                  label="Passengers"
                  type="number"
                  min="1"
                  placeholder="e.g. 10"
                  browser-autocomplete="off"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Number of Passengers Is Required',
                            error: 'Number of Passengers Is Required',
                          }),
                        ]
                      : []
                  "
                  @input="() => $emit('get-pricing')"
                />
              </v-flex>
              <v-flex xs3>
                <CRInput
                  :id="`quote-form-trip-${tripIndex}-num-drivers`"
                  v-model="tripData.requiredDrivers"
                  label="Drivers"
                  type="number"
                  min="1"
                  placeholder="e.g. 1"
                  browser-autocomplete="off"
                  :disabled="isModeView || quote.isPreBooking"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Number of Drivers Is Required',
                            error: 'Number of Drivers Is Required',
                          }),
                        ]
                      : []
                  "
                  @input="() => $emit('get-pricing')"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap align-center>
              <v-flex xs12 sm6 md6 lg6>
                <v-checkbox
                  v-model="vehicleRequiredEntireTrip"
                  class="vehicle-required"
                  :disabled="isModeView"
                  label="Vehicle Required for Entire Trip"
                  @change="vehicleNeededForTripChanged"
                />
              </v-flex>
              <v-flex xs12 sm6 md6 lg6>
                <v-checkbox
                  v-model="tripData.mountain"
                  class="mountain-trip"
                  :disabled="isModeView"
                  label="Mountain Trip"
                  style="white-space: nowrap"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <div
                v-if="paidAmenitiesList"
                class="d-flex align-center margin-b-3"
              >
                <v-icon class="yellow-msg">add_box</v-icon>
                <span>{{ paidAmenitiesList }}</span>
              </div>
            </v-layout>
          </v-flex>
          <v-flex md12 lg6>
            <div
              v-for="(vehicle, vehicleIndex) in tripData.requiredVehicles"
              :key="`trip-${tripIndex}-vehicle-type-${vehicleIndex}-${tripData.requiredVehicles.length}`"
            >
              <v-form ref="trip-vehicle-detail-form">
                <v-layout padded>
                  <v-flex class="xs7">
                    <CRSelect
                      :id="`quote-form-trip-${tripIndex}-vehicle-type-${vehicleIndex}`"
                      v-model="vehicle.vehicleType.id"
                      :items="vehicleTypes"
                      item-value="id"
                      item-text="label"
                      label="Vehicle Type"
                      placeholder="e.g. Charter Bus, Limousine, etc"
                      browser-autocomplete="off"
                      :disabled="isModeView"
                      use-svg-icons
                      :rules="
                        formSubmitted
                          ? [
                              isRequired(true, isNotEmpty, {
                                req: 'Vehicle Type Is Required',
                                error: 'Vehicle Type Is Required',
                              }),
                            ]
                          : []
                      "
                      @input="() => $emit('get-pricing')"
                    />
                  </v-flex>
                  <v-flex class="xs3">
                    <CRInput
                      :id="`quote-form-trip-${tripIndex}-num-vehicles-${vehicleIndex}`"
                      v-model="vehicle.quantity"
                      label="Vehicles"
                      type="number"
                      min="1"
                      placeholder="e.g. 1"
                      tabindex="0"
                      browser-autocomplete="off"
                      :disabled="
                        isModeView || !!vehicle.vehicleId || quote.isPreBooking
                      "
                      :rules="
                        formSubmitted
                          ? [
                              isRequired(true, isNotEmpty, {
                                req: 'Number of Vehicles Is Required',
                                error: 'Number of Vehicles Is Required',
                              }),
                            ]
                          : []
                      "
                      @input="() => $emit('get-pricing')"
                    />
                  </v-flex>
                  <v-flex xs1 layout>
                    <v-btn
                      v-if="!isModeView && tripData.requiredVehicles.length > 1"
                      :id="`quote-form-trip-${tripIndex}-vehicle-${vehicleIndex}-remove-button`"
                      icon
                      style="margin-top: 26px !important"
                      @click="removeVehicle(vehicleIndex)"
                    >
                      <CRIcon class="delete-stop-icon">trash</CRIcon>
                    </v-btn>
                  </v-flex>

                  <div>&nbsp;</div>
                </v-layout>
              </v-form>
            </div>
            <p>
              <a
                v-if="!isModeView && !quote.isPreBooking"
                :id="`quote-form-trip-${tripIndex}-vehicle-add-button`"
                class="vehicle-add-button"
                @click="addVehicle"
              >
                + Add Another Vehicle Type
              </a>
            </p>
          </v-flex>
        </v-layout>
      </v-form>

      <v-form v-if="tripData.hasCirculation" ref="circulation-start-end-form">
        <v-layout row>
          <v-flex xs4 class="margin-r-3">
            <CRInput
              :id="`quote-form-trip-${tripIndex}-circulation-start-date`"
              v-model="tripData.circulationStartDate"
              label="Start Date"
              type="date"
              :disabled="isModeView"
              :rules="formSubmitted && tripData.hasCirculation
                ? [ isRequired(true, isNotEmpty, {
                  req: 'Start Date Is Required',
                  error: 'Start Date Is Required',
                })] : []"
              @input="(val) => onCirculationStartDateChange(val)"
            />
            <CRInput
              :id="`quote-form-trip-${tripIndex}-circulation-end-date`"
              v-model="tripData.circulationEndDate"
              label="End Date"
              type="date"
              :disabled="isModeView"
              :rules="formSubmitted && tripData.hasCirculation
                ? [ isRequired(true, isNotEmpty, {
                  req: 'End Date Is Required',
                  error: 'End Date Is Required',
                })] : []"
              @input="(val) => onCirculationEndDateChange(val)"
            />
          </v-flex>
          <v-flex xs4 class="margin-r-3">
            <CRInput
              :id="`quote-form-trip-${tripIndex}-circulation-start-time`"
              v-model="tripData.circulationStartTime"
              label="Start of Service"
              type="time"
              :disabled="isModeView"
              :rules="formSubmitted && tripData.hasCirculation
                ? [ isRequired(true, isNotEmpty, {
                  req: 'Start Time Is Required',
                  error: 'Start Time Is Required',
                })] : []"
              @change="(val) => onCirculationStartTimeChange(val)"
            />
            <CRInput
              :id="`quote-form-trip-${tripIndex}-circulation-end-time`"
              v-model="tripData.circulationEndTime"
              label="End of Service"
              type="time"
              :disabled="isModeView"
              :rules="formSubmitted && tripData.hasCirculation
                ? [ isRequired(true, isNotEmpty, {
                  req: 'End Time Is Required',
                  error: 'End Time Is Required',
                })] : []"
              @change="(val) => onCirculationEndTimeChange(val)"
            />
          </v-flex>
        </v-layout>
      </v-form>

      <v-layout v-if="canDisableAutoClocking && quote.charterUpQuote" row justify-end padded>
        <v-flex shrink class="disable-auto-dropoff">
          <v-checkbox
            v-model="isAutoDropOffTimesDisabled"
            :disabled="isModeView"
            label="Disable Auto Drop-Off Times"
          />
        </v-flex>
      </v-layout>

      <!-- STOPS -->

      <Draggable
        :list="tripData.stops"
        :disabled="isModeView"
        ghost-class="ghost"
        @start="() => updateCachedStops()"
        @end="() => handleReorderedStops()"
      >
        <div
          v-for="(stop, stopIndex) in tripData.stops"
          :key="`quote-form-trip-${tripIndex}-stop-${stopIndex}`"
          style="margin-top: 10px"
        >
          <v-form ref="trip-stop-detail-form" autocomplete="off">
            <v-layout align-start fill-height>
              <v-flex xs11>
                <v-layout row wrap stop-container align-end padded>
                  <v-flex
                    v-if="
                      shouldPickupShow(tripData.stops, stopIndex) ||
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    xs12
                    lg4
                    order-xs1
                    order-lg1
                    style="padding-left: 8px"
                  >
                    <div class="d-flex align-center">
                      <template v-if="!op(stop, 'address/addressName')">
                        <CRAddressSelectorV2
                          v-if="isKnownAddressEditor"
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-address-name`"
                          class="cr-input-stop-address"
                          :label="getStopLabel(stop, stopIndex)"
                          placeholder="Type to search"
                          :disabled="isModeView"
                          :prefill-items="tripDataStopsV2"
                          legacy-border
                          :style="
                            $cr.breakpoint.smAndDown
                              ? `padding-bottom: 18px`
                              : `padding-bottom: 29px`
                          "
                          prefill-numbering-key="stopIndex"
                          :rules="
                            formSubmitted
                              ? [
                                  isRequired(
                                    true,
                                    () => validateAddress(stop.address),
                                    {
                                      req: 'Stop Address Is Required',
                                      error: 'Stop Address Is Required',
                                    }
                                  ),
                                  (v) =>
                                    validateAddressState(stop.address) ||
                                    'Address Must Contain State',
                                  (v) =>
                                    validateAddressCountry(stop.address) ||
                                    'Address Must Contain Country',
                                ]
                              : []
                          "
                          @change:place="
                            placeSelected(
                              stop,
                              $event,
                              stopIndex === 0,
                              stopIndex
                            )
                          "
                        />
                        <CRAddressSelector
                          v-else
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-address-name`"
                          class="cr-input-stop-address"
                          :label="
                            op(stop, 'address/title')
                              ? `Stop #${stopIndex + 1} - ${op(
                                  stop,
                                  'address/title'
                                )}`
                              : `Stop #${stopIndex + 1}`
                          "
                          placeholder="Type to search"
                          :use-formatted-detail="true"
                          :identifier="`trip_${tripIndex}_stop_${stopIndex}_address`"
                          :disabled="isModeView"
                          :prefill-items="tripDataStops"
                          legacy-border
                          :style="
                            $cr.breakpoint.smAndDown
                              ? `padding-bottom: 18px`
                              : `padding-bottom: 29px`
                          "
                          prefill-numbering-key="stopIndex"
                          :rules="
                            formSubmitted
                              ? [
                                  isRequired(
                                    true,
                                    () => validateAddress(stop.address),
                                    {
                                      req: 'Stop Address Is Required',
                                      error: 'Stop Address Is Required',
                                    }
                                  ),
                                  (v) =>
                                    validateAddressState(stop.address) ||
                                    'Address Must Contain State',
                                  (v) =>
                                    validateAddressCountry(stop.address) ||
                                    'Address Must Contain Country',
                                ]
                              : []
                          "
                          @place-selected="
                            (selectedData) =>
                              placeSelected(
                                stop,
                                selectedData,
                                stopIndex === 0 ? true : false,
                                stopIndex
                              )
                          "
                        />
                      </template>
                      <CRInput
                        v-show="stop.address && stop.address.addressName"
                        :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-address-name`"
                        class="cr-input-stop-address"
                        :label="
                          op(stop, 'address/title')
                            ? `Stop #${stopIndex + 1} - ${op(
                                stop,
                                'address/title'
                              )}`
                            : `Stop #${stopIndex + 1}`
                        "
                        type="text"
                        clear-icon="replay"
                        :clearable="true"
                        :readonly="true"
                        :disabled="isModeView"
                        style="padding-bottom: 9px"
                        prepend-inner-icon="$vuetify.icons.bookIt"
                        :value="op(stop, 'address/addressName') || ''"
                        :rules="
                          formSubmitted
                            ? [
                                isRequired(
                                  true,
                                  () => validateAddress(stop.address),
                                  {
                                    req: 'Stop Address Is Required',
                                    error: 'Stop Address Is Required',
                                  }
                                ),
                                (v) =>
                                  validateAddressState(stop.address) ||
                                  'Address Must Contain State',
                                (v) =>
                                  validateAddressCountry(stop.address) ||
                                  'Address Must Contain Country',
                              ]
                            : []
                        "
                        @click:prepend-inner="() => editAddressTitle(stop)"
                        @click:clear="clearStopAddress(stop)"
                      />
                      <CRIcon
                        v-if="
                          !isModeView &&
                          isKnownAddressEditor &&
                          stop &&
                          stop.address &&
                          stop.address.addressName
                        "
                        :key="`known-address-icon-${stopIndex}-trip-${tripIndex}-${stop.address.knownAddressId}`"
                        color="primary"
                        class="max-w-24 margin-t-n3 margin-l-2"
                        view-box="0 0 24 24"
                        @click.native="
                          editKnownAddress(stop.address, stopIndex)
                        "
                      >
                        {{
                          stop.address.knownAddressId
                            ? 'place_filled'
                            : 'place_outline'
                        }}
                      </CRIcon>
                    </div>
                  </v-flex>
                  <v-flex
                    v-if="!tripData.hasCirculation && shouldDropOffShow(tripData.stops, stopIndex)"
                    xs12
                    lg8
                    order-xs2
                    order-lg2
                  >
                    <v-layout align-end style="padding-bottom: 8px">
                      <v-flex
                        v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                        :class="$cr.breakpoint.mdAndDown && 'padded-dropoff'"
                        xs4
                        text-xs-center
                        style="padding-bottom: 30px"
                      >
                        <div
                          class="stop-type-container"
                          style="margin-left: 8px"
                        >
                          <span
                            :class="{
                              'can-cancel': stopIndex > 0,
                              'smaller-text': $cr.breakpoint.xsOnly,
                            }"
                          >
                            <span v-if="$cr.breakpoint.xsOnly">DO</span>
                            <span v-else>Drop-off</span>
                            <v-icon
                              v-if="!isModeView"
                              :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-dropoff-cancel-button`"
                              class="stop-type-icon"
                              small
                              color="white"
                              @click="() => clearStopDateTime(stop, 'dropoff')"
                            >
                              cancel
                            </v-icon>
                          </span>
                        </div>
                      </v-flex>
                      <v-flex
                        v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                        xs4
                        style="padding-right: 9px"
                      >
                        <CRInput
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-dropoff-date`"
                          v-model="stop.dropoffDate"
                          label="Drop-Off Date"
                          :default-date="lastSelectedDate"
                          type="date"
                          :rules="[
                            (v) =>
                              callValidateStopDate(tripData.stops, stopIndex) ||
                              'Stop Dates Must Be Chronological',
                          ]"
                          :disabled="isModeView"
                          @input="() => dateChange(stop, null, 'dropoff')"
                        />
                      </v-flex>
                      <v-flex
                        v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                        xs4
                      >
                        <CRInput
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-dropoff-time`"
                          v-model="stop.dropoffTime"
                          label="Drop-Off Time"
                          type="time"
                          :class="
                            stopIndex === tripData.stops.length - 1
                              ? 'dropoff-time-container'
                              : ''
                          "
                          :rules="[
                            (v) =>
                              callValidateStopTime(tripData.stops, stopIndex) ||
                              'Stop Times Must Be Chronological',
                          ]"
                          :disabled="isModeView"
                          @change="onDropoffChange"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    order-xs5
                    order-sm4
                    order-lg3
                    class="pb-3"
                    :class="tripData.hasCirculation ? 'lg12' : 'lg4'"
                    align-self-start
                  >
                    <v-btn
                      v-if="
                        !isModeView &&
                        (!stop.stopNotes ||
                          !stop.stopNotes[0] ||
                          (!stop.stopNotes[0].html &&
                            !stop.stopNotes[0].note)) &&
                        !stop.notes &&
                        !stop.flightInformation
                      "
                      :id="`trip-detail-stop-${stopIndex}-button-add-spot-time`"
                      color="primary"
                      flat
                      small
                      @click="editStopNote(stop)"
                    >
                      Add Stop Note
                    </v-btn>
                    <v-flex
                      v-else
                      :key="stop.notes"
                      xs12
                      style="padding-left: 12px"
                    >
                      <p>
                        Stop #{{ stopIndex + 1 }} Notes
                        <span
                          v-if="!isModeView"
                          class="edit-button"
                          @click="editStopNote(stop)"
                        >
                          Edit
                        </span>
                      </p>
                      <div>
                        <p
                          v-if="stop.stopNotes && stop.stopNotes[0]"
                          v-html="
                            stop.stopNotes[0].html || stop.stopNotes[0].note
                          "
                        />
                        <p v-else>{{ stop.notes }}</p>
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex
                    v-if="!tripData.hasCirculation && shouldPickupShow(tripData.stops, stopIndex)"
                    :align-self-start="
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    xs12
                    lg8
                    :order-lg4="shouldDropOffShow(tripData.stops, stopIndex)"
                    :order-lg2="!shouldDropOffShow(tripData.stops, stopIndex)"
                    order-xs3
                  >
                    <v-layout align-end justify-end style="padding-bottom: 8px">
                      <v-flex
                        v-if="shouldPickupShow(tripData.stops, stopIndex)"
                        xs4
                        text-xs-center
                        style="padding-bottom: 30px"
                      >
                        <div
                          class="stop-type-container"
                          style="margin-left: 8px"
                        >
                          <span
                            :class="{
                              'can-cancel': stopIndex > 0,
                              'smaller-text': $cr.breakpoint.xsOnly,
                            }"
                          >
                            <span v-if="$cr.breakpoint.xsOnly">PU</span>
                            <span v-else>Pickup</span>
                            <v-icon
                              v-if="!isModeView && stopIndex > 0"
                              :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-pickup-cancel-button`"
                              class="stop-type-icon"
                              small
                              color="white"
                              @click="() => clearStopDateTime(stop, 'pickup')"
                            >
                              cancel
                            </v-icon>
                          </span>
                        </div>
                      </v-flex>
                      <v-flex
                        v-if="shouldPickupShow(tripData.stops, stopIndex)"
                        xs4
                        class="py-0 pl-0 pr-2"
                      >
                        <CRInput
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-pickup-date`"
                          v-model="stop.pickupDate"
                          label="Pickup Date"
                          type="date"
                          :default-date="lastSelectedDate"
                          :disabled="isModeView"
                          :rules="
                            formSubmitted &&
                            shouldValidateStopDateTime(
                              tripData.stops,
                              stopIndex
                            )
                              ? [
                                  isRequired(true, isNotEmpty, {
                                    req: 'Pickup Date Is Required',
                                    error: 'Pickup Date Is Required',
                                  }),

                                  (v) =>
                                    callValidateStopDate(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Dates Must Be Chronological',
                                ]
                              : [
                                  (v) =>
                                    callValidateStopDate(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Dates Must Be Chronological',
                                ]
                          "
                          @input="() => dateChange(stop, stopIndex, 'pickup')"
                        />
                      </v-flex>
                      <v-flex
                        v-if="shouldPickupShow(tripData.stops, stopIndex)"
                        xs4
                      >
                        <CRInput
                          :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-pickup-time`"
                          v-model="stop.pickupTime"
                          label="Pickup Time"
                          class="pickup-time-container"
                          type="time"
                          :disabled="isModeView"
                          :rules="
                            formSubmitted &&
                            shouldValidateStopDateTime(
                              tripData.stops,
                              stopIndex
                            )
                              ? [
                                  isRequired(true, isNotEmpty, {
                                    req: 'Pickup Time Is Required',
                                    error: 'Pickup Time Is Required',
                                  }),
                                  (v) =>
                                    callValidateStopTime(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Times Must Be Chronological',
                                ]
                              : [
                                  (v) =>
                                    callValidateStopTime(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Times Must Be Chronological',
                                ]
                          "
                          @change="onPickupChange(stop, stopIndex)"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-if="
                      $cr.breakpoint.lgAndUp &&
                      shouldPickupShow(tripData.stops, stopIndex) &&
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    lg4
                    order-lg5
                  >
                    <!-- SPACER -->
                  </v-flex>
                  <v-flex
                    v-if="!tripData.hasCirculation && shouldPickupShow(tripData.stops, stopIndex)"
                    xs12
                    sm8
                    lg8
                    order-xs4
                    order-sm5
                    order-lg6
                    align-self-start
                  >
                    <v-layout justify-end class="pb-3">
                      <v-flex
                        v-if="stop.spotTime && stop.spotTime.spotTime"
                        xs12
                      >
                        <v-layout row style="margin-left: -4px">
                          <v-flex xs0 lg4></v-flex>
                          <v-flex xs6 lg4 style="padding-right: 2px">
                            <CRSelect
                              v-if="stop.spotTime"
                              :id="`quote-form-trip-${tripIndex}-spot-drop-down`"
                              :value="
                                getSpotTime(
                                  stop.spotTime.spotTime,
                                  stop.pickupDate,
                                  stop.pickupTime
                                )
                              "
                              :items="timeAtStopOptions"
                              item-value="value"
                              item-text="label"
                              label="Time at Stop"
                              placeholder="minutes"
                              use-svg-icons
                              browser-autocomplete="off"
                              :disabled="isModeView"
                              @input="setSpotTime(stop, $event)"
                            />
                          </v-flex>
                          <v-flex
                            v-if="stop.spotTime"
                            xs6
                            lg3
                            style="padding-left: 10px"
                          >
                            <div style="margin-bottom: 15px">Spot Time</div>

                            <span v-if="stop.spotTime.spotTime">
                              {{ stop.spotTime.spotTime | formatTime }}
                            </span>
                          </v-flex>
                          <v-layout
                            v-if="!isModeView"
                            xs1
                            justify-end
                            style="margin-top: 34px"
                          >
                            <v-icon
                              color="primary"
                              @click="clearSpotTime(stop)"
                            >
                              close
                            </v-icon>
                          </v-layout>
                        </v-layout>
                      </v-flex>
                      <v-flex v-else xs6 class="spot-time-button">
                        <v-btn
                          v-if="!isModeView"
                          :id="`trip-detail-stop-${stopIndex}-button-add-spot-time`"
                          color="primary"
                          flat
                          small
                          :disabled="!stop.pickupTime"
                          @click="addSpotTime(stop)"
                        >
                          Add Spot Time
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex v-if="events.length && stopIndex === 0" xs12>
                    <v-layout
                      v-for="(event, eventIndex) in events"
                      :key="`event-row-${eventIndex}`"
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        layout
                        align-center
                        style="margin-left: 10px"
                      >
                        <CRIcon class="mr-2" :color="getEventSeverity(event)">
                          alert
                        </CRIcon>
                        <span class="event-label">Event Alert:</span>
                        <b style="padding: 0px 5px">
                          {{ (event.title || '').toUpperCase() }}
                        </b>
                        {{
                          `${event.startDate} through ${event.endDate} ${
                            event.comments || ''
                          }`
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- BEGIN ICONS -->
              <v-flex
                xs1
                style="
                  flex: 1;
                  display: flex;
                  justify-content: center;
                  align-content: center;
                  flex-direction: column;
                "
              >
                <div style="height: 111px">
                  <v-btn
                    v-if="!isModeView && tripData.stops.length > 1"
                    icon
                    class="mt-0"
                  >
                    <v-icon color="blue">unfold_more</v-icon>
                  </v-btn>
                  <br v-if="!isModeView && tripData.stops.length > 1" />
                  <v-btn
                    v-if="!isModeView && stopIndex > 0"
                    :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-calculate-departure-time`"
                    :disabled="isArrivalEstimatorDisabled(stopIndex)"
                    icon
                    class="mt-0"
                    @click="calculateArrivalTime(stopIndex)"
                  >
                    <v-icon color="blue">schedule</v-icon>
                  </v-btn>
                  <br v-if="!isModeView && stopIndex > 0" />
                  <v-btn
                    v-if="!isModeView && tripData.stops.length > 1"
                    :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-remove-button`"
                    icon
                    class="mt-0"
                    @click="removeStop(stopIndex)"
                  >
                    <CRIcon class="delete-stop-icon">trash</CRIcon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </Draggable>

      <v-dialog
        v-model="showStopAddressTitleDialog"
        max-width="400"
        height="400"
      >
        <v-card class="cr-modal">
          <v-card-title>
            <h2>Address Title</h2>
          </v-card-title>
          <v-card-text>
            <CRInput
              :id="`quote-form-trip-${tripIndex}-address-title`"
              v-model="activeAddressTitle"
              type="text"
              :clearable="true"
              :autofocus="false"
              clear-icon="replay"
              :disabled="isModeView"
              @click:clear="activeAddressTitle = undefined"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :id="`quote-form-trip-${tripIndex}-cancel-address-dialog-button`"
              color="primary"
              outline
              @click="showStopAddressTitleDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :id="`quote-form-trip-${tripIndex}-set-address-button`"
              class="address-title-save"
              color="primary"
              style="margin-right: 0"
              :disabled="isModeView"
              @click="setStopAddressTitle"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="lower-checkboxes">
        <v-layout row justify-end padded class="pt-1 pb-3">
          <v-layout v-if="!isNewTripAmenitiesEnabled" xs6 row wrap>
            <v-flex shrink class="mr-3">
              <v-checkbox
                v-model="tripData.spab"
                :disabled="isModeView"
                label="SPAB"
              />
            </v-flex>
            <v-flex shrink class="mr-3">
              <v-checkbox
                v-model="tripData.mountain"
                :disabled="isModeView"
                label="Mountain Trip"
                style="white-space: nowrap"
              />
            </v-flex>
            <v-flex shrink>
              <v-checkbox
                v-model="tripData.ada"
                :disabled="isModeView"
                label="ADA"
              />
            </v-flex>
          </v-layout>
          <v-spacer />
          <v-flex shrink>
            <v-btn
              v-if="!isModeView"
              :id="`quote-form-trip-${tripIndex}-add-stop-button`"
              class="btn-primaryaction"
              @click="addStop(1)"
            >
              Add Stop
            </v-btn>
          </v-flex>
          <v-flex xs1 />
        </v-layout>
      </div>

      <div class="trip-footer">
        <QuoteFormCalculations
          ref="quote-form-calculations"
          :trip-data="tripData"
          :toggled="toggled"
          :trip-index="tripIndex"
          :live-mile-label="liveMileLabel"
          :dead-mile-label="deadMileLabel"
          :pricing-market="pricingMarket"
          :estimated-time-label="estimatedTimeLabel"
          :pricing-time-label="pricingTimeLabel"
          :calculating-estimations="calculatingEstimations"
          :quote="quote"
          :mode="mode"
          @get-pricing="() => $emit('get-pricing')"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import op from 'simple-object-path'
import events from '@/services/events'
import { deepClone } from '@/utils/deepClone'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import { DateTime, Duration } from 'luxon'
import { scrubQuoteForAPI } from '@/utils/quoteUtils'
import { mapActions } from 'vuex'
import { findPermissionByName } from '@/utils/permissions'

import {
  isNotEmpty,
  isRequired,
  validateEmail,
  validateAddress,
  validateTripFormGroups,
  validateStopTime,
  validateStopDate,
  validateAddressState,
  validateAddressCountry,
} from '@/utils/validators'

import Draggable from 'vuedraggable'
import QuoteFormCalculations from './QuoteFormCalculations.vue'
import { EventBus } from '@/utils/event-bus'
import { buildAddressTypes, isAirportAddress } from '@/utils/address'
import { paidAmenityTypeIds, SplitFeatureFlag } from '@/utils/enum'
import { mapGetters } from 'vuex'

const PARENT_RESERVATION_TYPE = 0

export default {
  components: {
    Draggable,
    QuoteFormCalculations,
  },
  filters: {
    formatTime(timeObject) {
      return DateTime.fromISO(timeObject)
        .toLocal()
        .toLocaleString(DateTime.TIME_SIMPLE)
    },
  },
  model: {
    prop: 'tripData',
  },
  props: {
    toggled: { type: Boolean, default: false },
    validationKey: { type: String, default: undefined },
    distanceUpdateKey: { type: String, default: undefined },
    tripIndex: { type: Number, default: null },
    tripTypes: { type: Array, default: () => [] },
    calculatingEstimations: { type: Boolean, default: false },
    vehicleTypes: { type: Array, default: () => [] },
    suggestedPricing: { type: Object, default: () => undefined },
    tripData: {
      type: Object,
      default: () => ({
        rates: [],
        charges: [],
        stops: [],
        distance: 0,
      }),
    },
    mode: { type: String, default: undefined },
    estimatedTimeLabel: { type: String, default: '' },
    deadMileLabel: { type: String, default: '' },
    liveMileLabel: { type: String, default: '' },
    pricingTimeLabel: { type: String, default: '' },
    pricingMarket: { type: Number, default: null },
    quote: { type: Object, default: () => {} },
    pricingData: { type: Array, default: () => [] },
    vehicleConflict: { type: Boolean },
    isNewTripAmenitiesEnabled: { type: Boolean, default: false },
    bookedByName: { type: String, default: '' },
  },
  data() {
    return {
      op,
      isNotEmpty,
      isRequired,
      validateEmail,
      validateAddress,
      validateAddressState,
      validateAddressCountry,
      validateStopTime,
      validateStopDate,
      filterCurrency: currencyFilter,
      formSubmitted: false,
      mockitems: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      showStopAddressTitleDialog: false,
      showStopNoteDialog: false,
      activeAddressTitle: undefined,
      activeStop: undefined,
      vehicleRequiredEntireTrip: false,
      isAutoDropOffTimesDisabled: false,
      events: [],
      stopTemplate: {
        address: {},
        createdOn: null,
        updatedOn: null,
        isPickupEstimated: false,
        isDropoffEstimated: false,
        isArrived: false,
        orderIndex: 0,
        startDatetime: null,
        pickupDatetime: null,
        dropoffDatetime: null,
        stopType: 'Pickup',
      },
      vehicleTemplate: { vehicleType: { id: null }, quantity: null },
      timeAtStopOptions: [
        {
          label: '5 Minutes',
          value: 5,
        },
        {
          label: '10 Minutes',
          value: 10,
        },
        {
          label: '15 Minutes',
          value: 15,
        },
        {
          label: '20 Minutes',
          value: 20,
        },
        {
          label: '25 Minutes',
          value: 25,
        },
        {
          label: '30 Minutes',
          value: 30,
        },
        {
          label: '45 Minutes',
          value: 45,
        },
        {
          label: '60 Minutes',
          value: 60,
        },
      ],
      lastSelectedDate: '',
      cachedStops: [],
      firstPickupDate: null,
      firstDropoffDate: null,
      areLoopingShuttleItinerariesEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isStopDatetimeValidationEnabled:
        'featureToggles/isStopDatetimeValidationEnabled',
    }),
    canDisableAutoClocking() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'can_disable_autoclocking_dropoff'
      )
    },
    paidAmenitiesList() {
      return this.bookedByName === 'Sales Bot'
        ? this.tripData.tripAmenities
            ?.filter((amenity) =>
              Object.values(paidAmenityTypeIds).includes(amenity.id)
            )
            .map((amenity) => amenity.label)
            .join(' | ')
        : null
    },
    tripDataStopsV2() {
      const stops = this.tripData?.stops
      const tripDataStops = []
      for (let i = 0; i < stops?.length; i++) {
        tripDataStops.push({ address: stops[i]?.address, stopIndex: i + 1 })
      }

      return tripDataStops
        ?.filter((s) => !!s?.address)
        ?.filter((s) => s?.address)
        ?.filter((s) => !!s?.address?.addressName)
        .map((s) => {
          return { ...s.address, stopIndex: s.stopIndex }
        })
    },
    tripDataStops() {
      let tripDataStops = []

      for (let i = 0; i < this.tripData.stops?.length; i++) {
        if (
          this.tripData.stops[i].address &&
          this.tripData.stops[i].address?.addressName
        ) {
          tripDataStops.push({
            place: this.tripData.stops[i].address,
            description: this.tripData.stops[i].address?.addressName,
            defaultAddressTitle: this.tripData.stops[i].address?.title,
            stopIndex: i + 1,
          })
        }
      }

      return tripDataStops
    },
    lastStopDate() {
      for (const stop of deepClone(this.tripData.stops).reverse()) {
        if (stop?.pickupDate) {
          return stop.pickupDate
        } else if (stop?.dropoffDate) {
          return stop.dropoffDate
        }
      }
      return null
    },
    isModeView() {
      return this.mode === 'view'
    },
    isShuttleTrip() {
      if (this.tripData.tripType?.id) {
        let shuttleTripIndex = this.tripTypes.findIndex(
          (tripType) => tripType.key === 'shuttle'
        )
        return this.tripTypes[shuttleTripIndex]?.id === this.tripData.tripType?.id
      }
      return false
    },
  },
  watch: {
    validationKey() {
      this.validateForms()
    },
    distanceUpdateKey() {
      this.updateDistance()
    },
    'tripData.vehicleNeededEntireTrip'() {
      this.vehicleRequiredEntireTrip = this.tripData.vehicleNeededEntireTrip
    },
    pricingMarket() {
      this.$emit('get-pricing')
    },
    'tripData.stops': {
      handler() {
        this.checkEvents()
      },
    },
    'quote.isPreBooking'() {
      if (this.quote.isPreBooking) {
        this.tripData.requiredDrivers = 1
        this.tripData.requiredVehicles = this.tripData.requiredVehicles.slice(
          0,
          1
        )
        this.tripData.requiredVehicles[0].quantity = 1
      }
    },
    'quote.charterUpQuote'(newVal) {
      const firstStop = this.tripData?.stops?.[0]
      if (
        newVal &&
        firstStop?.address?.addressName &&
        firstStop?.pickupDate &&
        firstStop?.pickupTime
      ) {
        this.addSpotTime(firstStop)
      } else if (!newVal) {
        this.clearSpotTime(firstStop)
      }
    },
  },
  async mounted() {
    EventBus.$on('add-new-stop-note', (stop, note, html) => {
      if (stop.stopNotes == null) {
        stop.stopNotes = []
      }
      stop.stopNotes.push({
        note: note,
        html: html,
      })
    })

    EventBus.$on(
      `quote-form-trip-${this.tripIndex}-edit-stop-location`,
      (address, stopIndex) => {
        this.tripData.stops[stopIndex].address = address
      }
    )

    this.areLoopingShuttleItinerariesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.LoopingShuttleItineraries
    )

    this.initializeLastSelectedDate()

    if (this.canDisableAutoClocking) {
      this.isAutoDropOffTimesDisabled = true
    }
  },
  beforeDestroy() {
    EventBus.$off(`quote-form-trip-${this.tripIndex}-edit-stop-location`)
  },
  methods: {
    ...mapActions({
      getQuoteTripEstimations: 'quotes/getQuoteTripEstimations',
      setShouldRecalculatePricing: 'quotes/setShouldRecalculatePricing',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    initializeLastSelectedDate() {
      if (!this.tripData.stops) return

      for (let i = this.tripData.stops.length - 1; i >= 0; i--) {
        let stop = this.tripData.stops[i]
        if (stop.dropoffDate) {
          this.lastSelectedDate = stop.dropoffDate
          break
        } else if (stop.pickupDate) {
          this.lastSelectedDate = stop.pickupDate
          break
        }
      }
    },
    callValidateStopDate(stops, stopIndex) {
      if (!this.isStopDatetimeValidationEnabled) {
        return true
      }

      return validateStopDate(stops, stopIndex)
    },
    callValidateStopTime(stops, stopIndex) {
      if (!this.isStopDatetimeValidationEnabled) {
        return true
      }

      return validateStopTime(stops, stopIndex)
    },
    updateSelectedVehicle() {
      this.$emit('vehicle-changed', this.tripIndex)
      this.force()
    },
    getEventSeverity(event) {
      const hasHighOrExtremeEvent = ['high', 'extreme'].includes(
        event.severityTypeKey
      )
      const hasMediumEvent = ['medium'].includes(event.severityTypeKey)
      const hasLowEvent = ['low'].includes(event.severityTypeKey)

      if (hasHighOrExtremeEvent) {
        return 'error'
      } else if (hasMediumEvent) {
        return 'warn'
      } else if (hasLowEvent) {
        return 'success'
      }
    },
    onTripTypeChange() {
      this.$emit('get-pricing')
      if (this.getIsRoundTrip() && this.tripData.stops.length <= 2) {
        this.addStop(3 - this.tripData.stops.length)
      }
    },
    getIsRoundTrip() {
      if (this.tripData.tripType.id) {
        let roundTripIndex = this.tripTypes.findIndex(
          (tripType) => tripType.key === 'round_trip'
        )
        return this.tripTypes[roundTripIndex].id === this.tripData.tripType.id
      }
      return false
    },
    force() {
      this.$forceUpdate()
    },
    async updateDistance() {
      const stopAddressArray = this.tripData.stops.map((stop) => {
        const { address } = stop
        const { lat = null, lng = null } = address || {}
        if (lat === null && lng === null) {
          return null
        }
        return { lat, lng }
      })
      const hasEmpty = stopAddressArray.some((latLng) => latLng === null)
      if (!hasEmpty) {
        let quoteClone = deepClone(this.quote)
        quoteClone = scrubQuoteForAPI(quoteClone, true)
        const tripEstimationsRequest = await this.getQuoteTripEstimations(
          quoteClone
        )
        const tripEstimationsData = tripEstimationsRequest.data
        const currentTripEstimation = tripEstimationsData[this.tripIndex]
        this.tripData.deadDistance = currentTripEstimation?.deadDistance
        this.tripData.drivingTime = currentTripEstimation?.duration
        this.tripData.distance = currentTripEstimation?.distance
        this.tripData.endDate = currentTripEstimation?.estimatedEndDate
        this.setCalendarDays()
      }
    },
    addStop(newStops) {
      const stops = this.tripData?.stops || []
      for (let i = 0; i < newStops; i++) {
        const newStop = deepClone(this.stopTemplate)
        if (stops.length > 0) {
          const lastStop = stops[stops.length - 1]
          if (lastStop && typeof lastStop.orderIndex !== 'undefined') {
            newStop.orderIndex = parseInt(lastStop.orderIndex, 10) + 1
          }
        }
        this.tripData.stops.push(newStop)
      }

      this.updateDistance()
    },
    removeStop(stopIndex) {
      this.tripData.stops.splice(stopIndex, 1)

      if (this.tripData.stops.length > 1) {
        this.clearPickupTime(this.tripData.stops.length - 1)
      }
      if (this.tripData.stops.length > 0) {
        this.clearDropoffTime(0)
      }

      this.tripData.stops.forEach((stop, stopIndex) => {
        stop.orderIndex = stopIndex
      })

      this.updateDistance()

      if (
        !this.isArrivalEstimatorDisabled(stopIndex) &&
        !this.isAutoDropOffTimesDisabled
      ) {
        this.calculateArrivalTime(stopIndex)
      }
    },
    clearPickupTime(stopIndex) {
      if (stopIndex < 0 || stopIndex >= this.tripData.stops.length) return
      if (this.tripData.stops[stopIndex].pickupDate) {
        this.tripData.stops[stopIndex].pickupDatetime = null
        this.tripData.stops[stopIndex].pickupDate = null
        this.tripData.stops[stopIndex].pickupTime = null
      }
    },
    clearDropoffTime(stopIndex) {
      if (stopIndex < 0 || stopIndex >= this.tripData.stops.length) return
      if (this.tripData.stops[stopIndex].dropoffDate) {
        this.tripData.stops[stopIndex].dropoffDatetime = null
        this.tripData.stops[stopIndex].dropoffDate = null
        this.tripData.stops[stopIndex].dropoffTime = null
      }
    },
    calcHourlyDiff() {
      const { stops = [] } = this.tripData
      const dates = []
      stops.forEach((stop, stopIndex) => {
        const { pickupDate, dropoffDate, pickupTime, dropoffTime } = stop

        if (pickupDate && stopIndex !== stops.length - 1) {
          if (pickupDate && pickupTime) {
            dates.push(DateTime.fromISO(pickupDate + 'T' + pickupTime))
          } else {
            dates.push(DateTime.fromISO(pickupDate))
          }
        }
        if (dropoffDate && stopIndex !== 0) {
          if (dropoffDate && dropoffTime) {
            dates.push(DateTime.fromISO(dropoffDate + 'T' + dropoffTime))
          } else {
            dates.push(DateTime.fromISO(dropoffDate))
          }
        }
      })
      this.$nextTick(() => {
        this.$emit('get-pricing')
      })
      dates.sort((a, b) => (a && a < b ? -1 : 0))

      if (dates.length === 0) {
        this.tripData.hourlyDiff = 0
        return
      }

      let hourlyDiff = 1
      if (dates.length === 1) {
        this.tripData.hourlyDiff = 0
        return
      } else {
        const first = dates[0]
        const last = dates[dates.length - 1]
        hourlyDiff = Math.trunc(last.diff(first, 'hours').as('hours'))
      }

      this.tripData.hourlyDiff = hourlyDiff
    },
    setCalendarDays() {
      const { stops = [] } = this.tripData
      const dates = []
      stops.forEach((stop, stopIndex) => {
        const { pickupDate, dropoffDate } = stop
        if (pickupDate && stopIndex !== stops.length - 1) {
          dates.push(DateTime.fromISO(pickupDate))
        }
        if (dropoffDate && stopIndex !== 0) {
          dates.push(DateTime.fromISO(dropoffDate))
        }
      })
      dates.sort((a, b) => (a < b ? -1 : 0))

      if (dates.length === 0) {
        this.tripData.calendarDays = 1
        this.calcHourlyDiff()
        return
      }

      let calendarDays = 0
      if (dates.length === 1) {
        calendarDays = 1
      } else {
        const first = dates.shift()
        const last = dates.pop()
        const diff = Math.trunc(last.diff(first, 'days').as('days'))
        calendarDays = diff + 1
      }

      this.tripData.calendarDays = calendarDays
      this.calcHourlyDiff()
    },
    clearStopDateTime(stop, stopType) {
      stop[`${stopType}Date`] = null
      stop[`${stopType}Time`] = null
      stop[`${stopType}_datetime`] = null
      stop[`${stopType}DateTime`] = null
      stop[`${stopType}Datetime`] = null
      this.setCalendarDays()
    },
    placeSelected(stop, selectedData, checkEvents = false, stopIndex) {
      selectedData.place.addressTypes =
        selectedData?.place?.addressTypes ||
        buildAddressTypes(selectedData?.place?.types)
      delete selectedData.place.types
      stop.address = selectedData.place
      stop.address.title = selectedData.defaultAddressTitle
      if (
        this.getIsRoundTrip() &&
        stopIndex === 0 &&
        this.tripData.stops.length === 3 &&
        Object.keys(this.tripData.stops[2].address).length === 0
      ) {
        this.tripData.stops[2].address = stop.address
        this.tripData.stops[2].address.title = stop.address.title
      }

      this.updateDistance()
      if (checkEvents) {
        this.checkEvents(stop)
      }

      this.$emit('get-pricing')

      if (
        stopIndex < this.tripData.stops.length - 1 &&
        !this.isArrivalEstimatorDisabled(stopIndex + 1) &&
        !this.isAutoDropOffTimesDisabled
      ) {
        this.calculateArrivalTime(stopIndex + 1)
      } else if (
        stopIndex <= this.tripData.stops.length - 1 &&
        !this.isArrivalEstimatorDisabled(stopIndex) &&
        !this.isAutoDropOffTimesDisabled
      ) {
        this.calculateArrivalTime(stopIndex)
      }

      if (
        this.quote.charterUpQuote &&
        stopIndex === 0 &&
        stop.pickupDate &&
        stop.pickupTime
      ) {
        this.addSpotTime(stop)
      }
    },
    updateCachedStops() {
      this.cachedStops = [...this.tripData.stops]
    },
    areStopsEqual(newStop, oldStop) {
      return (
        newStop.address === oldStop.address &&
        newStop.pickupDate === oldStop.pickupDate &&
        newStop.pickupTime === oldStop.pickupTime &&
        newStop.dropoffDate === oldStop.dropoffDate &&
        newStop.dropoffTime === newStop.dropoffTime
      )
    },
    async handleReorderedStops() {
      this.tripData.stops.forEach((newStop, i) => {
        let oldStop = this.cachedStops[i]
        if (
          this.tripData.stops.length == 2 &&
          !this.areStopsEqual(newStop, oldStop)
        ) {
          if (i == 0) {
            this.firstPickupDate = deepClone(this.tripData.stops[1].pickupDate)
            this.firstDropoffDate = deepClone(
              this.tripData.stops[0].dropoffDate
            )

            newStop.pickupTime = oldStop.pickupTime
            newStop.pickupDatetime = oldStop.pickupDatetime
            newStop.pickupDate = this.firstPickupDate
            newStop.stopType = 'Pickup'

            oldStop.dropoffTime = newStop.dropoffTime
            oldStop.dropoffDatetime = newStop.dropoffDatetime
            oldStop.dropoffDate = this.firstDropoffDate
            oldStop.stopType = 'Dropoff'

            this.tripData.stops[0].dropoffTime = null
            this.tripData.stops[0].dropoffDate = null
            this.tripData.stops[0].dropoffDatetime = null
            this.tripData.stops[1].pickupTime = null
            this.tripData.stops[1].pickupDate = null
            this.tripData.stops[1].pickupDatetime = null

            this.tripData.stops[0].orderIndex = 0
            this.tripData.stops[1].orderIndex = 1
          }
        } else {
          if (!this.areStopsEqual(newStop, oldStop)) {
            newStop.orderIndex = i
            if (
              !this.isArrivalEstimatorDisabled(i) &&
              !this.isAutoDropOffTimesDisabled
            ) {
              this.calculateArrivalTime(i)
            }
            if (i == this.tripData.stops.length - 1) {
              newStop.pickupDate = null
              newStop.pickupTime = null
              newStop.pickupDatetime = null
            }
            if (i == 0) {
              newStop.dropoffDate = null
              newStop.dropoffTime = null
              newStop.dropoffDatetime = null
            }
          }
        }
      })

      if (this.validationKey) {
        this.validateForms()
      }

      this.setCalendarDays()
      await this.checkEvents()

      if (this.firstPickupDate && this.tripData.stops[0].pickupDate == '') {
        this.tripData.stops[0].pickupDate = this.firstPickupDate
        this.firstPickupDate = null
      }
      if (this.firstDropoffDate && this.tripData.stops[1].dropoffDate == '') {
        this.tripData.stops[1].dropoffDate = this.firstDropoffDate
        this.firstDropoffDate = null
      }

      this.updateDistance()
      this.$emit('get-pricing')
    },
    async checkEvents(stop) {
      if (!this.currentUser?.company) {
        return
      }

      this.events = []
      if (!stop) {
        stop = this.tripData?.stops?.[0] || {}
      }
      const { pickupDate, address } = stop
      if (
        address &&
        address.lat &&
        address.lng &&
        pickupDate &&
        this.lastStopDate
      ) {
        const { lat, lng } = address
        const params = {
          pickupDate,
          dropOffDate: this.lastStopDate,
          lat,
          lng,
          radius: 100,
        }
        const eventsData = await events.eventByLocation(params)
        const eventList = eventsData.data || []
        this.events = eventList.map((event) => {
          event.startDate = DateTime.fromISO(event.startDate)
            .toUTC()
            .toISODate()
          event.endDate = DateTime.fromISO(event.endDate).toUTC().toISODate()
          return event
        })
        this.events.sort(this.eventComparator)
      }
    },
    eventComparator(a, b) {
      let aNum = this.getEventComparatorNumeric(a)
      let bNum = this.getEventComparatorNumeric(b)
      return aNum >= bNum
    },
    getEventComparatorNumeric(obj) {
      switch (obj.severityTypeKey) {
        case 'extreme':
          return 4
        case 'high':
          return 3
        case 'medium':
          return 2
        case 'low':
          return 1
        default:
          return 0
      }
    },
    clearStopAddress(stop) {
      stop.address = {}
      this.updateDistance()
      this.checkEvents()
      this.activeAddressTitle = undefined
      this.activeStop = undefined
      this.$emit('get-pricing')
    },
    editStopNote(stop) {
      const component = () => import('./StopInfoSidebar.vue')
      this.activeStop = stop
      const isAirportStop = isAirportAddress(stop?.address?.addressTypes)
      this.$store.dispatch('app/openSidebarDialog', {
        reservationId: this.reservationId,
        data: {
          reservationId: this.reservationId,
          activeStop: this.activeStop,
          reservationType: 0,
          title: 'Stop Notes',
          isAirportStop: isAirportStop,
          isLastStop: stop.orderIndex == this.tripData?.stops.length - 1,
        },
        component,
      })
    },
    editKnownAddress(address, stopIndex) {
      let title = 'Edit Stop Location'
      const component = () => import('./KnownAddressSidebar.vue')
      this.$store.dispatch(
        'app/openSidebarDialog',
        {
          data: { address, stopIndex, title, tripIndex: this.tripIndex },
          component,
        },
        true
      )
    },
    editAddressTitle(stop) {
      this.activeStop = stop
      if (stop.address && stop.address.title) {
        this.activeAddressTitle = stop.address.title
      } else {
        this.activeAddressTitle = undefined
      }
      this.showStopAddressTitleDialog = true
    },
    setStopAddressTitle() {
      if (this.activeStop.address) {
        this.activeStop.address.title = this.activeAddressTitle
        this.activeAddressTitle = undefined
      }
      this.showStopAddressTitleDialog = false
    },
    addVehicle() {
      this.tripData.requiredVehicles.push(
        Object.assign({}, deepClone(this.vehicleTemplate))
      )
    },
    removeVehicle(vehicleIndex) {
      if (this.tripData.requiredVehicles.length > 1) {
        this.tripData.requiredVehicles.splice(vehicleIndex, 1)
      }
    },
    toggleStopType(stop) {
      if (stop.stopType === 'Pickup') {
        stop.stopType = 'Drop off'
        stop.pickupDate = undefined
        stop.pickupTime = undefined
      } else if (stop.stopType === 'Drop off') {
        stop.stopType = 'Pickup'
        stop.dropoffDate = undefined
        stop.dropoffTime = undefined
      }
    },
    validateForms() {
      const formNames = [
        'trip-detail-form',
        'trip-vehicle-detail-form',
        'trip-stop-detail-form',
        'circulation-start-end-form',
      ]
      validateTripFormGroups.call(this, formNames, 'detail')
    },
    shouldDropOffShow(stops, stopIndex, isAddress) {
      if (stopIndex === 0) {
        return false
      }
      if (isAddress && stopIndex === stops.length - 1) {
        return false
      }
      return true
    },
    shouldPickupShow(stops, stopIndex) {
      if (stops.length > 1 && stopIndex === stops.length - 1) {
        return false
      }
      return true
    },
    shouldValidateStopDateTime(stops, stopIndex) {
      if (stopIndex === 0) {
        return true
      }
      if (stops.length > 1 && stopIndex === stops.length - 1) {
        return true
      }
      return false
    },
    onCirculationStartDateChange(startDate) {
      this.tripData.stops[0].pickupDate = startDate
      this.dateChange(this.tripData.stops[0], 0, 'pickup')
    },
    onCirculationEndDateChange(endDate) {
      this.tripData.stops[this.tripData.stops.length - 1].dropoffDate = endDate
      this.dateChange(this.tripData.stops[this.tripData.stops.length - 1], this.tripData.stops.length - 1, 'dropoff')
    },
    onCirculationStartTimeChange(startTime) {
      this.tripData.stops[0].pickupTime = startTime
      this.onPickupChange(this.tripData.stops[0], 0)
    },
    onCirculationEndTimeChange(endTime) {
      this.tripData.stops[this.tripData.stops.length - 1].dropoffTime = endTime
      this.onDropoffChange(this.tripData.stops[this.tripData.stops.length - 1], this.tripData.stops.length - 1)
    },
    async dateChange(stop, stopIndex, stopType) {
      const currentValue = stop[`${stopType}Time`]
      const currentValueDate = stop[`${stopType}Date`]
      if (!currentValue && currentValueDate) {
        stop[`${stopType}Time`] = '12:00'
      }
      if (stopIndex !== null) {
        this.setDueDateKey(stopIndex)
      }

      if (stop.spotTime) {
        this.setSpotTime(stop, stop.spotTime.timeAtStop)
      }
      const currentDate = stop[`${stopType}Date`]
      if (currentDate) {
        this.lastSelectedDate = currentDate
      }

      if (
        stopType === 'pickup' &&
        !this.isArrivalEstimatorDisabled(stopIndex + 1) &&
        !this.isAutoDropOffTimesDisabled
      ) {
        await this.calculateArrivalTime(stopIndex + 1)
      }

      this.checkEvents()

      this.setCalendarDays()
      this.$emit('get-pricing')

      this.$set(this.tripData.stops, stopIndex, this.tripData.stops[stopIndex])
      this.$emit('pickup-date-time-changed')

      if (
        this.quote.charterUpQuote &&
        stopIndex === 0 &&
        stop.address?.addressName &&
        stop.pickupTime
      ) {
        this.addSpotTime(stop)
      }
    },
    setDueDateKey(stopIndex) {
      this.checkEvents()
      if (stopIndex === 0) {
        this.$emit('set-due-date')
      }
    },
    async calculateArrivalTime(stopIndex) {
      const stop = this.tripData.stops[stopIndex]
      const previousStop = this.tripData.stops[stopIndex - 1]
      let quoteClone = deepClone(this.quote)
      quoteClone = scrubQuoteForAPI(quoteClone, true)
      const tripEstimationsRequest = await this.getQuoteTripEstimations(
        quoteClone
      ).catch((e) => e)
      const tripEstimationsData = tripEstimationsRequest.data
      const tripEstimate = tripEstimationsData[this.tripIndex]
      const duration = tripEstimate.timesFromPreviousStop[stopIndex]
      const SECONDS_TO_MILLIS = 1000
      const durationDateTime = Duration.fromMillis(duration * SECONDS_TO_MILLIS)

      const previousStopPickupTime = DateTime.fromISO(
        `${previousStop?.pickupDate}T${
          this.tripData.stops[stopIndex - 1]?.pickupTime
        }`,
        { zone: previousStop.address.timeZone }
      )
      let arrivalTime = previousStopPickupTime
        .plus(durationDateTime)
        .setZone(stop.address.timeZone)

      arrivalTime = this.roundDateTimeUpToQuarterHour(arrivalTime)
      stop.dropoffDatetime = arrivalTime.toISO()
      stop.dropoffDate = arrivalTime.toISODate()
      stop.dropoffTime = arrivalTime.toFormat('HH:mm')

      this.$set(this.tripData.stops, stopIndex, stop)
      this.$forceUpdate()
    },
    roundDateTimeUpToQuarterHour(dateTime) {
      const minuteOffset =
        Math.ceil(dateTime.minute / 15) * 15 - dateTime.minute
      return dateTime.plus({
        minutes: minuteOffset,
        seconds: -1 * dateTime.second,
      })
    },
    addSpotTime(stop) {
      stop.spotTime = {
        spotTime: null,
        timeAtStop: 15,
      }
      this.setSpotTime(stop)
    },
    getSpotTime(spotTime, pickupDate, pickupTime) {
      if (!spotTime || !pickupDate || !pickupTime) return null

      let spotTimeObj = DateTime.fromISO(spotTime)
      let pickupTimeObj = DateTime.fromFormat(
        `${pickupDate} ${pickupTime}`,
        'yyyy-MM-dd HH:mm'
      )
      let diff = spotTimeObj.diff(pickupTimeObj, 'minutes')

      let timeAtStop = Math.round(Math.abs(diff.minutes) / 5) * 5
      return timeAtStop
    },
    setSpotTime(stop, event) {
      stop.spotTime.timeAtStop = event ? event : 15

      const MILLIS_TO_MINUTES = 1000 * 60
      const durationDateTime = Duration.fromMillis(
        stop.spotTime?.timeAtStop * MILLIS_TO_MINUTES
      )
      let pickupTimeISO = `${stop.pickupDate} ${stop.pickupTime}`
      stop.spotTime.spotTime = DateTime.fromFormat(
        pickupTimeISO,
        'yyyy-MM-dd HH:mm'
      )
        .minus(durationDateTime)
        .toISO()
      this.$forceUpdate()
    },
    clearSpotTime(stop) {
      stop.spotTime = null
      this.$forceUpdate()
    },
    onPickupChange(stop, stopIndex) {
      this.calcHourlyDiff()
      if (stop.spotTime) {
        this.setSpotTime(stop)
      }
      this.$set(this.tripData.stops, stopIndex, stop)
      this.$emit('pickup-date-time-changed')

      if (
        stopIndex < this.tripData.stops.length - 1 &&
        !this.isArrivalEstimatorDisabled(stopIndex + 1) &&
        !this.isAutoDropOffTimesDisabled &&
        !this.tripData.hasCirculation
      ) {
        this.calculateArrivalTime(stopIndex + 1)
      }

      if (
        this.quote.charterUpQuote &&
        stopIndex === 0 &&
        stop.address?.addressName &&
        stop.pickupDate &&
        !this.tripData.hasCirculation
      ) {
        this.addSpotTime(stop)
      }
    },
    onDropoffChange(stop, stopIndex) {
      this.calcHourlyDiff()
      this.$set(this.tripData.stops, stopIndex, stop)
    },
    getDropoffStartDate(stops, stopIndex) {
      if (stopIndex >= stops.length || stopIndex < 1) {
        return ''
      }

      let { pickupDate } = stops[stopIndex - 1]
      return pickupDate ? pickupDate : ''
    },
    getPickupStartDate(stops, stopIndex) {
      if (stopIndex >= stops.length || stopIndex < 1) {
        return ''
      }

      let { dropoffDate } = stops[stopIndex]
      return dropoffDate ? dropoffDate : ''
    },
    isArrivalEstimatorDisabled(stopIndex) {
      const stop = this.tripData?.stops?.[stopIndex]
      const previousStop = this.tripData?.stops?.[stopIndex - 1]
      const isPreviousStopFilledOut =
        previousStop?.address?.addressName &&
        previousStop?.pickupDate &&
        previousStop?.pickupTime
      return !isPreviousStopFilledOut || !stop?.address?.addressName
    },
    stopTitleLongerThan(stop, threshold) {
      return stop.address?.title && stop.address?.title?.length > threshold
    },
    vehicleNeededForTripChanged(value) {
      this.$emit('set-vehicle-needed-for-trip', {
        value,
        tripIndex: this.tripIndex,
      })
      this.setShouldRecalculatePricing(value)
    },
    getStopLabel(stop, stopIndex) {
      const stopNumber = `Stop #${stopIndex + 1}`
      const title = stop?.address?.title
      if (!title) {
        return stopNumber
      }
      return `${stopNumber} - ${title}`
    },
  },
}
</script>

<style lang="scss" scoped>
.trip-detail {
  max-width: 1545px;
}

.trip-detail-info {
  margin-top: 24px;
}

.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}

.trip-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ghost {
  background: $primary;
  opacity: 0.075;
}

.stop-container {
  padding: 8px;
  border: 1px rgba(211, 211, 211, 0.5) solid;
  border-radius: 4px;
}

.stop-type-container {
  display: flex;
  align-content: center;
  padding: 9px;
  margin-top: -4px;
  margin-right: 8%;
  margin-left: 8%;
  color: $white;
  text-transform: uppercase;
  background-color: $gray-medium-light;
  border-radius: 4px;

  span {
    position: relative;
    width: 100%;
    font-size: 14px;

    &.can-cancel {
      right: 6px;

      &.smaller-text {
        right: 3px;
      }
    }
  }

  .stop-type-icon {
    position: absolute;
    right: -4px;
    margin-top: 3px;
  }
}

.delete-stop-icon {
  color: $gray-mid-light !important;

  &:hover {
    color: $error !important;
  }
}

.events-table {
  padding: 20px;
  margin: 0 10px;
  color: $white;
  background-color: $gray;
  border-radius: 4px;

  a {
    font-weight: bold;
    color: $white;
  }
}

.event-label {
  flex: 0 0 auto;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  color: $error;
}

.event-row {
  padding-bottom: 4px;
}

.address-title-save {
  margin-right: 8px;
}

.spot-time-button {
  color: $primary;
}

::v-deep .cr-modal {
  .v-text-field__details {
    display: none !important;
  }
}

.add-stop-notes {
  &--pickup-md,
  &--pickup-lg {
    margin-top: -57px;
    position: absolute;
  }

  &--dropoff {
    margin-top: -15px;
  }
}

.disable-auto-dropoff {
  margin-right: 125px;
  height: 35px;
}

.lower-checkboxes {
  margin-bottom: 50px;
}

.pt-1 {
  height: 35px;
}

.vehicle-required {
  max-height: 50px;
}

.mountain-trip {
  max-height: 50px;
}

.yellow-msg {
  color: $yellow-new;
  margin-right: 10px;
}

@media only screen and (max-width: 1900px) and (min-width: 1264px) {
  .padded-top-layout {
    .stop-date-row {
      padding-top: 21px;
    }
  }
}

@media only screen and (min-width: 1263px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 277px;
    }
  }
}

@media only screen and (min-width: 1500px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 349px;
    }
  }
}

@media only screen and (min-width: 1900px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 469px;
    }
  }
}
</style>
